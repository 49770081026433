.avatarImg {
    width: 200px;
    height: 200px;
    box-shadow: 1px 1px 4px 1px grey;
    border-radius: 50%;
    /* overflow: hidden; */
    position: relative;
}

.avatarImg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profileImg {
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 50%;
    position: relative;
}

.editIcon {
    position: absolute;
    bottom: 16px;
    right: 6px;
    cursor: pointer;
}

.editIcon input {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}


.profileTitle {
    color: #1976d2;
    font-weight: 500;
}

.profileTitle span {
    color: #808080;
    font-weight: 500;
}

.levelName {
    color: #717D89;
    font-weight: 500;
}


@media only screen and (max-width: 600px) {
    .profileImg {
        width: 100px;
        height: 100px;
    }

    .avatarImg {
        width: 100px;
        height: 100px;
    }

    .editIcon {
        position: absolute;
        bottom: 0px;
        right: 0px;
        cursor: pointer;
    }
}