.companySelect {
  width: auto;
  margin: 8px;
  font-size: 14;
  border: 0px solid;
  background: #fff;
  color: #000;
  font-weight: 500;
  /* text-transform: uppercase; */
  padding: 4px;
}

.productCatSelect {
  width: 100%;
  min-width: 135;
  font-size: 12;
  /* text-transform: lowercase; */
  border: 0px solid;
  padding: 5px;
}

.companySelect option {
  color: #000;
}

.companySelect option:disabled {
  background: rgba(255, 0, 0, 0.568);
  color: #f9f9f9 !important;
}

.companySelect option:disabled:first-child {
  background: rgba(255, 0, 0, 0.192);
}

.productCatSelect option:disabled {
  background: rgba(255, 0, 0, 0.568);
  color: #f9f9f9 !important;
}

.productCatSelect option:disabled:first-child {
  background: rgba(255, 0, 0, 0.192);
}
