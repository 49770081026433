@media only screen and (max-width: 1350px) {
    .main_layout .right {
        padding: 10px 20px;
        overflow: auto;
    }
}

@media only screen and (max-width: 1200px) {}


@media only screen and (max-width: 900px) {
    .main_layout .right {
        padding: 5px 10px;
        overflow: auto;
    }

    .CTMain {
        height: auto !important;
    }

    [data-sticky-td] {
        background: transparent;
        position: static !important;
        color: #222222d7;
    }

    [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px transparent;
    }
}

@media only screen and (max-width: 992px) {
    .main_layout {
        grid-template-columns: 1fr;
    }

    .main_layout .left {
        background: #fff;
        box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    }


    .sidebar {
        position: fixed;
        width: 200px;
        background: #fff;
        top: 0px;
        left: 0px;
        transition: all linear 0.3s;
        z-index: 999;
    }

    .sidebar_logo {
        height: 70px;
        background: var(--theme-color);
        display: flex;
        align-items: center;
        justify-content: space-between;
        display: flex;
        align-items: center;
        padding-right: 10px;
    }

    .hide-nav {
        left: -200px;
    }

    .header {
        grid-template-columns: 1fr;
    }

    .logo_link {
        display: none;
    }

    .show-nav {
        left: 0px;
    }

    .nav-overlay {
        display: block;
    }

    .nav-overlay {
        width: 100%;
        height: 100%;
        background: rgba(40, 72, 135, 0.596);
        position: fixed;
        left: 0px;
        top: 0px;
        transition: all linear 0.2s;
        z-index: 900;
    }

    .hide-nav-overlay {
        left: -1000px;
    }

    .show-nav-overlay {
        left: 0px;
    }

    .humberger_menu {
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    .header_right .left h1 {
        font-size: 20px;
    }

    .header_right .profile h4 {
        font-size: 16px;
    }

    .home_topBar h1 {
        font-size: 20px;
    }

    .__filter {
        display: flex;
        gap: 8px;
        border-bottom: 1px solid #284887;
        flex-direction: column;
    }

    .__filter p {
        width: fit-content;
    }

    .pagination-grid-main {
        flex-direction: column;
        gap: 20px;
    }

    .paginationBtns a {
        border-radius: 8px;
        padding: 3px 6px;
        font-size: 12px;
        margin-right: 0px;
    }
}

@media only screen and (min-width: 768px) {
    .ExpandTableMain td {
        padding: 4px 10px;
        border-bottom: 0px solid rgba(128, 128, 128, 0.158);
    }
}

@media only screen and (max-width: 600px) {
    .header_right .left h1 {
        font-size: 16px;
    }

    .header_right .profile div:first-child {
        display: none;
    }

    .home_topBar {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
    }

    .search_input {
        width: 100%;
    }

    .table_div table {
        width: 550px;
    }

    .header_right .left {
        gap: 10px;
    }

    .humberger_menu {
        font-size: 18px;
    }
}

@media only screen and (max-width: 320px) {}