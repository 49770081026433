/* ----- Table component start -----------*/
/* -------------------------------------- */
.table_div {
  overflow: auto;
}

.table_div table {
  width: 100%;
}

.table_div table th {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-align: start;
  background: var(--theme-color);
  padding: 0.5rem;
  font-weight: 600;
  border: 1px solid #fff;
}

.table_div table td {
  font-size: 14px;
  text-align: start;
  padding: 0.2rem 0.1rem 0.2rem 0.3rem;
  /* white-space: nowrap; */
}

.table_div table tr {
  color: #222222d7;
  font-weight: 400;
}

.table_div table tr:nth-child(even) {
  background: #1976d21c;
  font-weight: 400;
}

.table_div table tr:nth-child(odd) {
  background: #ffffff52;
  font-weight: 400;
}

.table_div table tr:hover {
  background: rgba(255, 255, 255, 0.589);
  color: #1976d2;
}

[data-sticky-td] {
  background: #cfd8dc;
  border-bottom: 1px solid #1976d252;
}

[data-sticky-last-left-td] {
  border-right: 1px solid #1976d2;
}

#expand_row {
  background: #fff;
}

#expand_row:hover {
  color: initial;
}

.ExpandTableMain table {
  width: 100%;
}

.ExpandTableMain td {
  padding: 4px 10px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.158);
  /* white-space: nowrap; */
}

/* -------------------------------------- */
/* ----- Table component end -----------*/

/* collection main */

.CTMain::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.upTable {
  width: '100%';
}

.upTable th {
  text-align: start;
}
