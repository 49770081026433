/* float circle  */
.circle_main {
  position: absolute;
  left: -10vh;
  top: -10vh;
  z-index: -5;
}

.circle {
  border-radius: 50%;
  position: absolute;
  left: -20vh;
  top: -20vh;
  z-index: -5;
  animation-name: circle;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@keyframes circle {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

.circle_1 {
  width: 40vh;
  height: 40vh;
  background: rgb(25, 118, 210);
  z-index: -1;
}

.circle_2 {
  width: 50vh;
  height: 50vh;
  background: rgba(25, 118, 210, 0.781);
  z-index: -2;
}

.circle_3 {
  width: 60vh;
  height: 60vh;
  background: rgba(25, 118, 210, 0.555);
  z-index: -3;
}

.circle_4 {
  width: 80vh;
  height: 80vh;
  background: rgba(25, 118, 210, 0.315);
  z-index: -4;
}

@media only screen and (max-width: 600px) {
  .circle_1 {
    width: 30vh;
    height: 30vh;
  }
  .circle_2 {
    width: 35vh;
    height: 39vh;
  }
  .circle_3 {
    width: 40vh;
    height: 44vh;
  }
  .circle_4 {
    width: 47vh;
    height: 50vh;
  }
}

/* float circle  */
