@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

:root {
  --theme-color: #1976d2;
}

a {
  text-decoration: none;
}

.w-100 {
  width: 100%;
}

/* mui*/
.Table_icon_btn {
  padding: 0 !important;
}

.Table_icon_btn.delete {
  padding: 0 !important;
  color: #d32f2f;
}

.MuiTabs-scroller {
  overflow: auto !important;
}

/* mui*/

.c-pointer {
  cursor: pointer;
}

/*------------ button start --------------*/
/* ----------------------------------------------- */
.__btn {
  padding: 10px 25px;
  font-size: 16px;
  border: 0px;
  border-radius: 5px;
}

.btn_blue {
  background: var(--theme-color);
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}

/*  */

/*------------ button end --------------*/
/* ----------------------------------------------- */

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.v-top {
  vertical-align: top;
}

/* create a custom scroll bar start */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(236, 239, 241, 0.5);
}

::-webkit-scrollbar-corner {
  background: rgba(236, 239, 241, 0.5);
}

::-webkit-scrollbar-thumb {
  background: rgba(144, 164, 174, 0.75);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(120, 144, 156, 1);
}

/* create a custom scroll bar end */

.MuiTypography-root {
  font-family: 'inter' !important;
}

td {
  word-break: break-word;
}

/* react to print */
@page {
  size: auto;
  margin: 10mm 5mm;
  /* size: landscape; */
}

th {
  padding: 0.5rem;
}

td {
  padding: 0.2rem 0.1rem 0.2rem 0.3rem;
}